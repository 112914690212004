<script>
import axios from "axios";

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Register component
 */
export default {
  page: {
    title: "Sửa dịch vụ vn-us ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
   
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
          {
          text: "Dịch vụ",
          href: "/danh-sach-dich-vu",
        },
        {
          text: "Sửa dịch vụ vn - us",
          active: true,
        },
      ],

      service: {
        name: "Gửi hàng vn-us",
        type: 1,
        vnus: [
          {
            name: "Giá niêm yết",
            lb_min: 10,
            values: [
              {
                weight: 1.1,
                price: 99.99,
                disc: 0,
                disc_unit: "$",
                disc_opitions: ["$", "%"],
              },
            ],
          },
          {
            name: "Giá đại lý",
            lb_min: 10,
            values: [
              {
                weight: 1.1,
                price: 99.99,
                disc: 0,
                disc_unit: "$",
                disc_opitions: ["$", "%"],
              },
            ],
          },
        ],
      },
      submitted: false
    };
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    addPrice(prices) {
      prices.push({
        weight: 0,
        price: 0,
        disc: 0,
        disc_unit: "$",
        disc_opitions: ["$", "%"],
      });
    },

    removePrice(prices, price) {
      prices.filter((item, index) => {
        if (item.weight == price.weight && prices.length > 1) {
          prices.splice(index, index);
        }
      });
    },

    // Try to register the service in with the email, name
    // and password they provided.
    tryToUpdate() {
      this.submitted = true;

      // stop here if form is invalid

      axios({
        url: process.env.VUE_APP_SERVER_URL + "/service/update",
        method: "post",
        headers: {
          authorization: user.token,
        },

        data: this.service,
      })
        .then(({ data }) => {
          this.$toasted.success("Sửa dịch vụ thành công");
        })
        .catch((error) => {
          this.$toasted.error("Sửa dịch vụ có lỗi");
        });
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let seft = this;
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/service/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          console.log(data);
          this.service = data;
        } else {
          this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
        this.$router.push("/");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader  :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card overflow-hidden">
    
          <div class="card-body pt-0">
         
            <b-form class="my-2 p-2" v-on:submit.prevent>
              <b-form-group
                class="mb-3"
                id="service-name"
                label="Tên dịch vụ"
                label-for="name"
              >
                <b-form-input
                  v-model="service.name"
                  type="text"
                  placeholder="Gửi hàng US-VN"
                ></b-form-input>
              </b-form-group>
              <div
                class="px-3"
                v-for="(vnus, index) in service.vnus"
                :key="vnus.name + vnus.type + index"
              >
                <b-row>
                  <b-col class="md-3">
                    <b-form-group
                      class="mb-3"
                      :label="'Tùy chọn ' + (index + 1)"
                      label-for="provide"
                    >
                      <b-form-input
                        v-model="vnus.name"
                        debounce="1000"
                        type="text"
                        placeholder="Name cho đại lý"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                   <b-col>
                      <b-form-group>
                        <span>Phí gửi VN-US </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="vnus.price"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                      <b-col>
                      <b-form-group>
                        <span>LB tối thiểu  </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="vnus.lb_min"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  <b-col>
                      <b-form-group>
                        <span>Giảm giá </span>
                        <div class="py-2">
                          <b-form-input
                            v-model="vnus.disc"
                            debounce="500"
                            type="number"
                            step="0.01"
                            min="0.00"
                            max="1000000.00"
                            placeholder="Nhập dữ liệu"
                          ></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <span>Đơn vị giảm giá</span>
                        <div class="py-2">
                          <b-form-select
                            class="form-control"
                            v-model="vnus.disc_unit"
                            size="md"
                            :options="['$', '%']"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                              <b-col>
                    <b-form-group>
                      <span>% lương</span>
                      <div class="py-2">
                        <b-form-input
                          v-model="vnus.salary"
                          debounce="500"
                          type="number"
                          step="0.01"
                          min="0.00"
                          max="1000000.00"
                          placeholder="Nhập dữ liệu"
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div style="height: 48px">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    @click="addPrice(vnus.values)"
                  >
                    Thêm Giá
                  </button>
                </div>
                <div
                  class="px-5"
                  v-for="(price, index) in vnus.values"
                  :key="price.weight + vnus.name + index"
                >
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="mb-3"
                        label="Khối lượng"
                        label-for="service-vnus-tax"
                      >
                        <b-form-input
                          debounce="1000"
                          v-model="price.weight"
                          type="number"
                          step="0.01"
                          min="0.00"
                          max="1000000.00"
                          placeholder="Nhập khối lượng"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="mb-3"
                        label="Giá dịch vụ"
                        label-for="service-vnus-tax"
                      >
                        <b-form-input
                          v-model="price.price"
                          debounce="1000"
                          type="number"
                          step="0.01"
                          min="0.00"
                          max="1000000.00"
                          placeholder="Giá cho khối lượng"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                
                    <b-col
                      class="col-1"
                      style="justify-self: center; display: flex"
                    >
                      <button
                        class="btn btn-sm btn-danger"
                        style="
                          float: left;
                          width: 30px;
                          height: 30px;
                          font-size: 11px;
                          align-self: center;
                        "
                        @click="removePrice(vnus.values, price)"
                        variant="outline-primary"
                      >
                        x
                      </button>
                    </b-col>
                  </b-row>

                  <hr />
                </div>
              </div>
              <div class="mt-4 d-grid">
                <b-button
                  type="submit"
                  @click="tryToUpdate"
                  variant="primary"
                  class="btn-block"
                  >Sửa dịch vụ</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>